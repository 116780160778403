import { Container, Grid } from "@mui/material";
import { TitleSubTitle } from "@platformx/utilities";
import { Fragment } from "react/jsx-runtime";
import builder from "./builder";

const DynamicSectionComponent = ({
  fields,
  state,
  setState,
  errors,
  register,
  clearErrors,
  isEdit,
  control,
  title,
  groupedFields,
  setGroupedFields,
  setError,
}: any) => {
  const getTitle = (validations, titl) => {
    const isRequired = validations?.find((x) => x?.type === "required" || x?.type === "uirequired");
    return isRequired ? `${titl}*` : titl;
  };
  return (
    <Container>
      <Grid container spacing={5} rowSpacing={2}>
        {fields
          ?.filter((x) => ("is_show" in x && x?.is_show) || !("is_show" in x))
          .map((field) => (
            <Fragment key={`${field?.name}_id`}>
              {field?.type === "tag" ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className='textFiled'
                  id={`${field?.name}_id`}>
                  {builder(
                    field,
                    state,
                    errors,
                    register,
                    clearErrors,
                    setState,
                    fields,
                    isEdit,
                    title,
                    control,
                    null,
                    setError,
                    groupedFields,
                    setGroupedFields,
                  )}
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={5} md={5} lg={5} className='leftFiled'>
                    <TitleSubTitle
                      titleVariant='h6medium'
                      subTitleVariant='p4regular'
                      title={getTitle(field?.validations, field?.title)}
                      subTitle={field?.description}
                    />
                  </Grid>
                  <Grid item xs={12} sm={7} md={7} lg={7} className='textFiled'>
                    {builder(
                      field,
                      state,
                      errors,
                      register,
                      clearErrors,
                      setState,
                      fields,
                      isEdit,
                      title,
                      control,
                      null,
                      setError,
                      groupedFields,
                      setGroupedFields,
                    )}
                  </Grid>
                </>
              )}
            </Fragment>
          ))}
      </Grid>
    </Container>
  );
};

export default DynamicSectionComponent;
