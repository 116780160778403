import { useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { FC, JSX } from "react";
import { useTranslation } from "react-i18next";

interface PropType {
  component: JSX.Element;
  Title: any;
  position?: any;
  className?: string;
}

export const XToolTip: FC<PropType> = ({
  component: Component,
  position = "left",
  Title,
  className,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Tooltip
      className={className}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: theme?.palette?.customColor?.BGLIGHT,
            fontWeight: "regular",
            color: theme?.palette?.customColor?.PRIMARY,
            fontSize: theme?.fontSize?.fontSize_12,
            boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.12)",
            maxWidth: "300px",
            textAlign: "center",
            padding: "10px",
            border: "0.1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: "3px",
          },
        },
      }}
      title={Title}
      placement={position}>
      <span style={{ cursor: "pointer" }}>{Component}</span>
    </Tooltip>
  );
};
