/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, FormControl, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { executeFunctionByName } from "../../../utils/customFunctions/index";
import { dynamicAPICall, fetchRequiredMessage } from "../../../utils/helperFns";
import HiddenTextBox from "../HiddenTextBox/HiddenTextBox";
import "./AutoCompleteDropDown.css";

const icon = <CheckBoxOutlineBlankIcon fontSize='small' sx={{ color: "#14142B" }} />;
const checkedIcon = <CheckBoxIcon fontSize='small' sx={{ color: "#14142B" }} />;
export type AutoCompleteMultiSelectProps = {
  options: any;
  state?: any;
  optionFormat?: string;
  field?: any;
  error: any;
  register: any;
  handleChangeOn?: any;
  groupedFields: any;
  setGroupedFields?: any;
};
export const AutoCompleteDropDown = ({
  options,
  state = [],
  optionFormat = "",
  field,
  error,
  register,
  handleChangeOn,
  groupedFields,
  setGroupedFields,
}: AutoCompleteMultiSelectProps) => {
  const { placeholder = "", multiple = true, dependent_name = "" } = field || {};
  const theme = useTheme();
  const [optionsList, setOptionsList] = useState(options || []);
  const [tempOptionList, setTempOptionList] = useState(options || []);

  const handleFocus = () => {
    const onFocusEvent = field?.events?.find((x) => x.event === "onFocus" && x.type === "function");
    const dependentField = state?.[field?.dependent_name];
    if (onFocusEvent?.value) {
      executeFunctionByName(onFocusEvent?.value, {
        tempOptionList,
        setOptionsList,
        dependentField,
      });
    }
  };

  const handleChange = (_: any, newValue) => {
    const ifAllOption = Array.isArray(newValue)
      ? newValue?.filter((item) => item?.id === "All")
      : [];
    handleChangeOn(ifAllOption?.length ? ifAllOption : newValue, field, multiple);
    const onSelectEvent = field?.events?.find(
      (x) => x.event === "onSelect" && x.type === "function",
    );
    if (onSelectEvent?.value) {
      executeFunctionByName(onSelectEvent?.value, {
        tempOptionList,
        setOptionsList,
        ifAllOption,
        optionsList,
      });
    }
    const onChangeEvent = field?.events?.find(
      (x) => x.event === "onChange" && x.type === "function",
    );
    if (onChangeEvent?.value) {
      executeFunctionByName(onChangeEvent?.value, {
        value: newValue,
        groupedFields,
        setGroupedFields,
      });
    }
  };
  const [defaultValues, setDefaultValues] = React.useState<any>([]);

  useEffect(() => {
    if (state[field.name] && optionsList?.length > 0) {
      const defaultValue = multiple
        ? state[field.name]?.filter((x) => optionsList?.some((y) => y.label === x.label))
        : optionsList.find((x) => x.label === state[field.name][0]?.label) || [];
      if (defaultValue) setDefaultValues(defaultValue);
    }
  }, [state[field.name], optionsList]);

  useEffect(() => {
    // Extract onload event and query
    const onloadGraphqlEvent = field?.events?.find(
      (x) => x.event === "onLoad" && x.type === "graphql",
    );
    if (onloadGraphqlEvent) {
      const { query = "", responseVariable = "" } = onloadGraphqlEvent;
      if (query && responseVariable) {
        dynamicAPICall(query, responseVariable).then((result) => {
          setOptionsList(result || []);
          setTempOptionList(result || []);
        });
      }
    }
    if (state[field.name]?.length > 0) {
      const onloadFunctionEvent = field?.events?.find(
        (x) => x.event === "onLoad" && x.type === "function",
      );
      if (onloadFunctionEvent?.value && state?.[field.name]?.length > 0) {
        executeFunctionByName(onloadFunctionEvent?.value, {
          value: state[field.name]?.[0],
          groupedFields,
          setGroupedFields,
        });
      }
    }
  }, []);

  useEffect(() => {
    const onFocusFun = field?.events?.find((x) => x.event === "onFocus" && x.type === "function");
    !onFocusFun && setOptionsList(options);
  }, [options]);

  useEffect(() => {
    // update setOptionList to all on initial page load if state has all option
    if (state[field.name] && tempOptionList?.length > 0) {
      const ifAllOption = Array.isArray(state[field.name])
        ? state[field.name]?.filter((item) => item?.id === "All")
        : [];
      if (ifAllOption?.length) setOptionsList(ifAllOption);
    }
  }, [tempOptionList]);

  return (
    <Box id={`${field?.name}_id`}>
      <FormControl fullWidth error={error}>
        <Autocomplete
          loading={tempOptionList?.length === 0}
          onFocus={handleFocus}
          value={defaultValues}
          popupIcon={<ExpandMoreIcon />}
          className='inviteMembersAutoComplete'
          multiple={multiple}
          id='checkboxesdemos'
          isOptionEqualToValue={(option: any, value) => {
            return option?.[optionFormat] === value?.[optionFormat];
          }}
          options={optionsList}
          disabled={dependent_name && !state[dependent_name]}
          disableCloseOnSelect={multiple}
          onChange={handleChange}
          getOptionLabel={(option) => {
            return option?.label || "";
          }}
          classes={{
            popper: "autocompleteMultiSelectPopper",
            paper: "autocompleteMultiSelectPapper",
            inputRoot: "autocompleteMultiSelectInputRoot",
            tag: "autocompleteMultiSelectTag",
          }}
          renderOption={(props, option: any, { selected }) => (
            <li {...props}>
              {multiple ? (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
              ) : null}
              {option[optionFormat]}
            </li>
          )}
          renderInput={(params) => {
            return (
              <TextField
                variant='filled'
                style={{
                  border: error ? `solid 1px ${theme.palette.customColor.ERROR}` : ``,
                  background: error ? "#FFEBEE" : "transparent",
                  borderRadius: "5px",
                }}
                {...params}
                placeholder={!defaultValues?.length && placeholder}
              />
            );
          }}
        />
      </FormControl>
      <HiddenTextBox
        error={error}
        value={state[field?.name]?.length || ""}
        register={register}
        name={field?.name}
        required={fetchRequiredMessage(field?.validations, state[field?.name])}
      />
    </Box>
  );
};
