import MagicUrl from "quill-magic-url";
import "quill-paste-smart";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill-new";
import "./Editor.css";

Quill.register("modules/magicUrl", MagicUrl);
type EditorDescriptionProps = {
  analytics: any;
  color?: string;
  optionEditor?: any;
  passingToHtml?: any;
  description?: string;
  className?: string;
  prelemType: string;
};

// const Block = Quill.import("blots/block");
// class MyBlock extends Block {
//   static create() {
//     const node = super.create();
//     // Next, customize other functions
//     node.setAttribute("style", "margin: 0 0 10px 0;");
//     return node;
//   }
// }
// Quill.register(MyBlock);

const EditorDescription = (props: EditorDescriptionProps) => {
  const {
    analytics = {},
    description = "",
    passingToHtml = () => {},
    optionEditor = [
      [{ list: "ordered" }, { list: "bullet" }],
      // [{ indent: "-1" }, { indent: "+1" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      // ["bold", "italic", "underline", "link"],
    ],
  } = props;

  const [value, setValue] = useState<string>("");
  const reactQuillRef = useRef<ReactQuill>(null);

  const editorText = (e: string) => {
    setValue(e);
    passingToHtml(e);
  };

  const isHTML = (str: any) => {
    const elem = document.createElement("p");
    elem.innerHTML = str;
    return elem.children.length > 0;
  };

  useEffect(() => {
    const input = document.querySelector("input[data-link]") as HTMLInputElement;
    input.dataset.link = "Enter a valid link";
    input.placeholder = "Enter a valid link";
  });

  useEffect(() => {
    if (description) {
      if (isHTML(description)) {
        setValue(description);
        passingToHtml(description);
      } else {
        setValue(`<p>${description}</p>`);
        passingToHtml(`<p>${description}</p>`);
      }
    }
  }, [description]);

  return (
    <ReactQuill
      modules={{
        toolbar: optionEditor,
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        },
        magicUrl: true,
      }}
      theme='bubble'
      value={value}
      preserveWhitespace
      ref={reactQuillRef}
      bounds={`#${analytics?.prelemID}_editor`}
      onChange={(v) => {
        // replace tabs with spaces
        editorText(v?.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;"));
      }}
    />
  );
};
export default React.memo(EditorDescription);
